<template>
  <div style="padding-top: 20px">
    <h2>Управление устройствами</h2>
    <div class="row m-2">
      <div class="card" style="width: 100%">
        <h3 class="p-2 text-left">
          Мой дом
        </h3>
        <div
          class="container"
          style="max-width: 100%"
          v-for="(room, idx) in rooms"
          :key="idx"
        >
          <h4>
            {{room.name}}
          </h4>
          <div
            class="row"
            style="padding: 0"
            v-if="room.devices"
          >
            <device-wrapper
              class="mb-3 col-xl-3 col-md-4 col-sm-12"
              style="line-height: 1.1"
              v-for="(device, indx2) in room.devices"
              @deviceClick="deviceClick(device)"
              :key="indx2"
              :device="device"
            />
          </div>
        </div>
      </div>

    </div>
    <el-dialog
      :visible.sync="deviceDialog"
      custom-class="device__dialog"
      title="Информация об устройстве"
    >
      <div v-if="selectedDevice.id">
        <div class="row">
          <div class="col-6">
            {{selectedDevice.author}}
          </div>
          <div class="col-6">
            {{selectedDevice.name}}
          </div>
        </div>
        <div
          class="row align-items-center mt-2"
          v-if="selectedDevice.possibilities && selectedDevice.possibilities['on-off']"
        >
          <div class="col-6">
            Состояние
          </div>
          <div class="col-6">
            <el-button
              :type="selectedDevice.possibilities['on-off'] === 'on' ? 'primary' : ''"
              @click="toggleOnOff"
            >
              {{selectedDevice.possibilities['on-off'] === 'on' ? 'Включено' : 'Выключено'}}
            </el-button>
          </div>
        </div>
        <hr>
        <div
          v-if="selectedDevice.possibilities && 'brightness' in selectedDevice.possibilities"
        >
          Яркость
          <!--
            /** TODO: если не будет использоваться удалить import
            <el-input-number
              v-model="selectedDevice.possibilities.brightness"
              :controls="false"
              size="mini"
              :min="0" :max="100"
            />
          -->
          <el-slider
            label="Яркость"
            input-size="medium"
            :show-input="true"
            :min="0" :max="100"
            :show-input-controls="false"
            :show-tooltip="false"
            @change="brightnessChange"
            v-model="selectedDevice.possibilities.brightness"
          />
<!--          <Slider
            :value="selectedDevice.possibilities.brightness"
            @change="brightnessChange"
          />-->
        </div>
        <div v-if="selectedDevice.possibilities && selectedDevice.possibilities.rgb">
          <hr>
          <ColorPicker
            :hue="color"
            @change="colorChange"
            class="mb-2"
            style="margin: 0 auto"
          ></ColorPicker>
          <h5>Цвет</h5>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ColorPicker from '@radial-color-picker/vue-color-picker';
import utils from '@/utils';
import DeviceWrapper from '../components/DeviceWrapper.vue';

export default {
  /**
   * Компонент страница управления устройствами
   */
  name: 'Devices',
  components: {
    DeviceWrapper,
    ColorPicker,
  },
  data() {
    return {
      deviceDialog: false,
      selectedDevice: {},
      color: null,
    };
  },
  computed: {
    ...mapGetters({
      rooms: 'rooms',
    }),
  },
  methods: {
    deviceClick(device) {
      /**
       * Функция обработчик события нажатия на устройство
       * открывает модальное окно с подробностями об устройстве
       * В текущей реализации работает только с лампочками, поскольку только у лампочек
       * есть изменяемые possibilities кроме включения/выключения
       */
      this.selectedDevice = device;
      if (device.possibilities) {
        if (device.possibilities.brightness) {
          this.selectedDevice.possibilities.brightness = Number(device.possibilities.brightness);
        }
        if (device.possibilities.rgb) {
          this.color = utils.hexToHSL(device.possibilities.rgb);
          console.log(this.color);
        }
      }
      this.deviceDialog = true;
    },
    toggleOnOff() {
      /**
       * Функция обработки изменения свойства вкл/выкл выбранного устройства
       */
      if (this.selectedDevice && this.selectedDevice.id
        && this.selectedDevice.possibilities['on-off']) {
        this.$store.dispatch('changePossibilityValue', {
          deviceId: this.selectedDevice.id,
          possibilityKey: 'on-off',
          newValue: this.selectedDevice.possibilities['on-off'] === 'on' ? 'off' : 'on',
        });
      }
    },
    brightnessChange() {
      /**
       * Функция обработки изменения свойства яркость выбранного устройства
       */
      if (this.selectedDevice && this.selectedDevice.id
        && this.selectedDevice.possibilities.brightness) {
        this.$store.dispatch('changePossibilityValue', {
          deviceId: this.selectedDevice.id,
          possibilityKey: 'brightness',
          newValue: this.selectedDevice.possibilities.brightness,
        });
      }
    },
    colorChange(color) {
      /**
       * Функция обработки изменения свойства цвета выбранного устройства
       */
      this.$store.dispatch('changePossibilityValue', {
        deviceId: this.selectedDevice.id,
        possibilityKey: 'rgb',
        newValue: utils.HSLToHex(color, 100, 50),
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';
.card {
  background: #3c4b73;
  border: solid 1px #4d5878;
  border-radius: 10px;
  color: white;
  box-shadow: 0 12px 15px 0 rgba(0,0,0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
h4 {
  text-align: left;
  text-decoration: underline;
  text-decoration-color: #195eda;
  text-decoration-thickness: 3px;
  text-underline-offset: 3px;
  margin-bottom: 10px;
}
@media (max-width: 500px), (max-height: 500px) {
  h2 {
    font-size: 22px;
    font-weight: bold;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
