<template>
  <div>
    <!--device wrapper {{device}}-->
    <component
      v-on:deviceClick="$emit('deviceClick', device)"
      v-if="component"
      :is="component"
      :device="device"
    />
  </div>
</template>

<script>
export default {
  /**
   * Компонент обертка над компонентами устройств,
   * осуществляет выбор и рендер необходимого компонента в зависимости
   * от типа устройства (socket, light, sensor)
   */
  name: 'DeviceWrapper',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      component: null,
      deviceDialog: false,
      selectedDevice: {},
    };
  },
  created() {
    /**
     * Выбор и динамический иморт нужного компонента
     * при создании компонента оболочки
     */
    switch (this.device.type) {
      case 'light':
        this.component = () => import('../components/Devices/Light');
        break;
      case 'socket':
        this.component = () => import('../components/Devices/Socket');
        break;
      case 'sensor':
        this.component = () => import('../components/Devices/Sensor');
        break;
      default:
        break;
    }
  },
};
</script>

<style lang="scss">

</style>
